const myPortalRoutes = [
    {
      path: '/e-upravnik',
      name: 'e-manager',
      meta: {
        breadcrumb: [
          {
            text: 'Upravnik',
            active: true
          }
        ],
        requiresAuth: true,
        requiresEmailConfirmation: true,
        pageId: 'e-manager'
      }
    },
    {
        path: '/e-upravnik/vase-enote',
        name: 'your-units',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Vaše enote',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'your-units'
        }
      },
      /*{
        path: '/e-upravnik/razdelilnik/redni-obracun',
        name: 'regular-billing',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Razdelilnik',
              active: true
            },
            {
              text: 'Redni obračun',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'divider-1'
        }
      },*/
      /*{
        path: '/e-upravnik/razdelilnik/rezervni-sklad',
        name: 'reserve-fund-divider',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Razdelilnik',
              active: true
            },
            {
              text: 'Rezervni sklad',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'divider-2'
        }
      },*/
      {
        path: '/e-upravnik/obracun/razdelilnik',
        name: 'card-traffic',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              //text: 'Kartica lastnika',
              text: 'Obračun',
              active: true
            },
            {
              text: 'Razdelilnik',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'card-owner-1'
        }
      },
      {
        path: '/e-upravnik/obracun/odprte-postavke',
        name: 'open-items',
        meta: {
          breadcrumb: [
            {
              text: 'Obračun',
              active: true
            },
            {
              text: 'Odprte postavke',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'card-owner-2'
        }
      },
      {
        //path: '/e-upravnik/skladi/rezervni-sklad',
        path: '/e-upravnik/sklad',
        name: 'reserve-fund-funds',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Sklad',
              active: true
            },
            {
              text: 'Sklad',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'fund-1'
        }
      },
      /*{
        path: '/e-upravnik/skladi/prostovoljni-sklad',
        name: 'voluntary-fund',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Skladi',
              active: true
            },
            {
              text: 'Prostovoljni sklad',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'fund-2'
        }
      },*/
      {
        path: '/e-upravnik/dokumenti/dokumenti-dobaviteljev',
        name: 'suppliers',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Dokumenti',
              active: true
            },
            {
              text: 'Dokumenti dobaviteljev',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'document-1'
        }
      },
      {
        path: '/e-upravnik/dokumenti-upravnika',
        name: 'manager-documents',
        meta: {
          breadcrumb: [
            {
              text: 'Dokumenti upravnika',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'manager-documents'
        }
      },
      {
        path: '/e-upravnik/odcitki',
        name: 'readings',
        meta: {
          breadcrumb: [
            {
              text: 'Odčitki',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'readings'
        }
      },
      {
        path: '/e-upravnik/oglasna-deska',
        name: 'bulletin-board',
        meta: {
          breadcrumb: [
            // {
            //   text: 'Upravnik',
            //   active: false,
            //   to: '/e-upravnik'
            // },
            {
              text: 'Oglasna deska',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'bulletin-board'
        }
      },
  {
    path: '/e-upravnik/oglasna-deska/:building',
    name: 'bulletin-board-building',
    meta: {
      breadcrumb: [
        {
          text: 'Oglasna deska',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'bulletin-board'
    }
  },
      {
        path: '/e-upravnik/oglasna-deska/obvestilo/:bulletin_id',
        name: 'bulletin',
        meta: {
          breadcrumb: [
            {
              text: 'Oglasna deska',
              active: false,
              to: '/oglasna-deska'
            },
            {
              text: 'Obvestilo',
              active: true
            }
          ],
          requiresAuth: true,
          requiresEmailConfirmation: true,
          pageId: 'bulletin-board-1'
        }
      },
  {
    path: '/e-upravnik/obvestila',
    name: 'notifications',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'Obvestila',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'notification'
    }
  },
  {
    path: '/e-upravnik/obvestila/:notification_id',
    name: 'notification',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'Obvestila',
          active: false,
          to: '/obvestila'
        },
        {
          text: 'Obvestilo',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'notification-1'
    }
  },
  {
    path: '/e-upravnik/obvestila/elektronsko-podpisovanje-listine',
    name: 'electronic-document-signing',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'Obvestila',
          active: false,
          to: '/obvestila'
        },
        {
          text: 'Elektronsko podpisovanje listine',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'notification-2'
    }
  },
  {
    path: '/e-upravnik/e-sestanki',
    name: 'meetings',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'E-sestanki',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'meeting'
    }
  },
  {
    path: '/e-upravnik/e-sestanki/:room_id',
    name: 'meeting',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'E-sestanki',
          active: false,
          to: '/e-sestanki'
        },
        {
          text: 'Sestanek',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'meeting-1'
    }
  },
  {
    path: '/e-upravnik/sporocila-upravniku',
    name: 'agent_messages',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'Obrazci in sporočila upravniku',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'agent-message'
    }
  },
  {
    path: '/prijavljena-stevila-oseb-in-psov',
    name: 'registered-number-of-people-and-dogs',
    meta: {
      breadcrumb: [
        {
          text: 'Prijavljena števila oseb in psov',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'registered-number-of-people-and-dogs'
    }
  },
  {
    path: '/e-upravnik/moji-kuponi',
    name: 'coupons',
    meta: {
      breadcrumb: [
        // {
        //   text: 'Upravnik',
        //   active: false,
        //   to: '/e-upravnik'
        // },
        {
          text: 'Moji kuponi',
          active: true
        }
      ],
      requiresAuth: true,
      requiresEmailConfirmation: true,
      pageId: 'coupon'
    }
  }
]
export default myPortalRoutes