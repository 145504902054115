export default {
  sl: {
    general: {
      profile: 'Profil',
      home: 'Domov',
      second_page: 'Druga stran',
      statistics: 'Statistika',
      no: 'Ne',
      yes: 'Da',
      cancel: 'Prekini',
      UGODNOSTI: 'UGODNOSTI',
      cosmetics: 'Kozmetika',
      furniture: 'Pohištvo',
      white_goods: 'Bela tehnika',
      home_accessories: 'Dodatki za dom',
      electronics: 'Elektronika',
      tools: 'Orodja',
      personal_care: 'Osebna nega',
      promotions_and_discounts: 'Akcije in popusti',
      NOVICE: 'NOVICE',
      new: 'Novica',
      news: 'Novice',
      E_SOSESKA: 'E-SOSESKA',
      e_soseska: 'E-soseska',
      TRZNICA: 'Tržnica',
      upravnik: 'Upravnik',
      portal: 'Portal',
      kontakti: 'Kontakti',
      eUPRAVNIK: 'eUPRAVNIK',
      add_ad: 'Dodaj oglas',
      item_page: 'izdelek',
      NEPREMIČNINE: 'NEPREMIČNINE',
      real_estate: 'Nepremičnina',
      real_estates: 'Nepremičnine',
      MOJ_PORTAL: 'MOJ PORTAL',
      add_real_estate: 'Dodaj nepremičnino',
      sale: 'Prodaja',
      rent: 'Oddaja',
      rental: 'Najem',
      replacement: 'Zamenjava',
      your_units: 'Vaše enote',
      divider: 'Obračun',
      card_owner: 'Kartica lastnika',
      funds: 'Sklad',
      documents: 'Dokumenti',
      manager_documents: 'Dokumenti upravnika',
      bulletin_board: 'Oglasna deska',
      notifications: 'Obvestila',
      meetings: 'E-sestanki',
      agent_messages: 'Obrazci in sporočila upravniku',
      reported_num_people_dogs: 'Prijavljena št. oseb in psov',
      your_manager: 'Vaš upravnik',
      regular_billing: 'Redni obračun',
      billing:'Obračun',
      reserve_fund_divider: 'Rezervni sklad',
      card_traffic: 'Kartica prometa',
      open_items: 'Odprte postavke',
      reserve_fund_funds: 'Rezervni sklad',
      voluntary_fund: 'Prostovoljni sklad',
      suppliers: 'Dobavitelji',
      readings:'Odčitki',
      awarding_terms_privacy: 'Pravila zasebnosti nagradne igre'

    },
  
    login: {
      greeting: 'Dobrodošli!',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se',
      wrong_password: 'Geslo je napačno!',
      login_as_user_error:'Pri vpisu je prišlo do napake!\nZaprite zavihek in ponovno poskusite na admin portalu.'
    }
   
  },
  en: {
      general: {
        profile: 'Profile',
        home: 'Home',
        second_page: 'Second page',
        statistics: 'Statistics',
        no: 'No',
        yes: 'Yes',
        cancel: 'Cancel'
      }
    },
    login: {
      greeting: 'Welcome!',
      login: 'Login',
      rememberMe: 'Remember me',
      password: 'Password',
      forgottenPassword: 'Forgotten password?',
      logout: 'Log out',
      wrong_password: 'Password is not correct!'
    }
}
